<template>
  <layout-base>
    <v-layout class="overflow-inherit!">
      <v-main>
        <div class="h-full">
          <v-container class="h-full">
            <v-layout full-height>
              <v-row
                class="h-full"
                justify="center"
                align-content="center"
                no-gutters
              >
                <v-col cols="12" md="4">
                  <v-row class="mb-4" justify="center" no-gutters>
                    <logo-chip size="50" />
                  </v-row>
                  <slot />
                  <v-card-actions>
                    <small>&copy;{{ new Date().getFullYear() }} Orie</small>
                    <v-spacer />
                    <small>
                      <router-link
                        class="dotted-link text--small ml-2"
                        :to="{
                          name: 'legal-handle',
                          params: { handle: 'terms' },
                        }"
                      >
                        Terms of Use
                      </router-link>
                      <span class="mx-2">|</span>
                      <router-link
                        class="dotted-link text--small"
                        :to="{
                          name: 'legal-handle',
                          params: { handle: 'privacy' },
                        }"
                      >
                        Privacy Policy
                      </router-link>
                    </small>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-layout>
          </v-container>
        </div>
      </v-main>
    </v-layout>
  </layout-base>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LayoutBase from "@/components/layout/modules/LayoutBase.vue";

export default defineComponent({
  components: {
    LayoutBase,
  },
});
</script>
